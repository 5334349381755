import { TextHistoryFilter } from './datamodels';

export class TextHistoryFilterModel implements TextHistoryFilter
{
  deviceID: number;
  translationTaskID: number;
  count: number;
  translationJobID: number;
  languageID: number;
  userID: number;
  screenID: number;
  companyID: number;
}
