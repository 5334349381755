import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, ReturnState, Device } from './../../datamodels.d'
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-device-newedit',
  templateUrl: './device-newedit.component.html',
  styleUrls: ['./device-newedit.component.css']
})

export class DeviceNewEditComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  deviceID: number = 0;

  public onSaved = new EventEmitter();

  public device: Device = {
    id: 0,
    name: '',
    translationTasks: null,
    expanded: true
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
  };

  ngOnInit()
  {
    if (this.deviceID === 0)
    {
      return;
    }

    if (this.deviceID > 0)
    {
      this.getDevice();
    }
  };

  getDevice()
  {
    this.http.get<Device>(this.baseUrl + 'api/devices/' + this.deviceID).subscribe(result =>
    {
      this.device = result;
    }, error => console.error(error));
  };

  saveDevice()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/devices/' + this.device.id, this.device)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.device = result.data as Device;
            this.snackBarRef = this.snackBar.open('Device saved.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }

          this.onSaved.emit();
        },
        error => console.error(error));
  };
}
