import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { State } from './../datamodels.d';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent
{
  public showLogin: boolean = true;
  public isLocal: boolean = false;

  title = 'app';
  state: State;

  http: HttpClient;
  baseUrl: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string)
  {
    this.http = http;
    this.baseUrl = baseUrl;

    this.doStateCheck();
  };

  doStateCheck()
  {
    this.http
      .get<State>(this.baseUrl + 'api/security/state')
      .subscribe(result =>
      {
        this.state = result;
        this.isLocal = this.state.isLocal === true;
      }, error => console.error(error));
  };

  onStateChangedHandler(event: State)
  {
    this.state = event;
    this.showLogin =
      ((this.state.action === 1 || this.state.action === 4) &&
        this.state.value === true) === false;
  };
}
