import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SmtpSettings, LogItem, ReturnState } from './../../datamodels.d'
import { SimpleSnackBar, MatSnackBarRef, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public displayedColumns: string[] = ['userDisplayName', 'level', 'createDate', 'name', 'text'];

  public settings: SmtpSettings = {
    smtpServer: '',
    smtpPort: 0,
    smtpAuthenticate: true,
    smtpUser: '',
    smtpPassword: '',
    smtpSslTls: true
  };

  public logs: LogItem[] = [];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private snackBar: MatSnackBar)
  {
    this.getSettings();
    this.getLogs();
  };

  getSettings()
  {
    this.http.get<SmtpSettings>(this.baseUrl + 'api/settings/setting').subscribe(result =>
    {
      this.settings = result;
    }, error => console.error(error));
  };

  getLogs()
  {
    this.http.get<LogItem[]>(this.baseUrl + 'api/settings/logs').subscribe(result =>
    {
      this.logs = result;
    }, error => console.error(error));
  };

  saveSettings()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/settings/setting/', this.settings)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.settings = result.data as SmtpSettings;
            this.snackBarRef = this.snackBar.open('Settings saved.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };
}
