//
// ===== File globals.ts    
//
'use strict';

import { Global } from './datamodels';

export let babel: Global = {
  ADMIN: 'A',
  DEV: 'D',
  TASK_CREATOR: 'TC',
  TRANSLATOR: 'T',

  technicalRoleNames: null,

  hasRole(_techRoleName: string): boolean
  {
    var has = this.technicalRoleNames !== null && this.technicalRoleNames.indexOf(_techRoleName) >= 0;
    return has;
  },

  missingRole(_techRoleName: string): boolean
  {
    var missing = !this.hasRole(_techRoleName);
    return missing;
  }
};
