import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language } from './../../datamodels.d'
import { HistoryComponent } from '../history/history.component';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-languages-summary',
  templateUrl: './languages-summary.component.html',
  styleUrls: ['./languages-summary.component.css']
})

export class LanguagesSummaryComponent
{
  dialogRef: MatDialogRef<HistoryComponent>;
  public languages: Language[];

  displayedColumns: string[] = ['name', 'culture'];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private dialog: MatDialog)
  {
    http.get<Language[]>(baseUrl + 'api/languages/all').subscribe(result =>
    {
      this.languages = result;
    }, error => console.error(error));
  };

  openHistory()
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
  };
}
