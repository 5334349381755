import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileDropModule } from 'ngx-file-drop';
//import { Screen, Language, JobExtended, CommentExtended, Status } from './interfaces/datamodels.d'

import
{
  MatTabsModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatButtonModule,
  MatRadioModule,
  MatSnackBarModule,
  MatDialogModule,
  MatListModule
} from '@angular/material'

//import { FrameComponent } from './frame.component';
import { AppComponent } from './app.component';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';

import { CounterComponent } from './counter/counter.component';
import { DevicesSummaryComponent } from './devices-summary/devices-summary.component';
import { TranslationJobNewComponent } from './translation-job-new/translation-job-new.component';
import { TranslationJobComponent } from './translation-job/translation-job.component';
import { TranslationJobScreensComponent } from './translation-job-screens/translation-job-screens.component';
import { ScreenSingleComponent } from './screen-single/screen-single.component';
import { LanguagesSummaryComponent } from './languages-summary/languages-summary.component';
import { LanguageNewEditComponent } from './language-newedit/language-newedit.component';
import { UsersSummaryComponent } from './users-summary/users-summary.component';
import { UserNewEditComponent } from './user-newedit/user-newedit.component';
import { CompaniesSummaryComponent } from './companies-summary/companies-summary.component';
import { CompanyNewEditComponent } from './company-newedit/company-newedit.component';
import { SettingsComponent } from './settings/settings.component';
import { TextHistoryComponent } from './text-history/text-history.component';
import { HistoryComponent } from './history/history.component';
import { DeviceNewEditComponent } from './device-newedit/device-newedit.component';
import { TranslationTaskNewEditComponent } from './translation-task-newedit/translation-task-newedit.component';
import { LanguagesAssignmentComponent } from './languages-assignment/languages-assignment.component'

@NgModule({
  declarations: [
    AppComponent,

    NavMenuComponent,
    LoginComponent,

    CounterComponent,
    DevicesSummaryComponent,
    TranslationJobNewComponent,
    TranslationJobComponent,
    TranslationJobScreensComponent,
    ScreenSingleComponent,
    LanguagesSummaryComponent,
    LanguageNewEditComponent,
    UsersSummaryComponent,
    UserNewEditComponent,
    CompaniesSummaryComponent,
    CompanyNewEditComponent,
    SettingsComponent,
    TextHistoryComponent,
    HistoryComponent,
    DeviceNewEditComponent,
    TranslationTaskNewEditComponent,
    LanguagesAssignmentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: DevicesSummaryComponent, pathMatch: 'full' },
      { path: 'device-new', component: TranslationJobNewComponent },
      { path: 'devices/:iddevice/tasks/:idtask/newjobs', component: TranslationJobNewComponent },

      { path: 'translation-job/:idjob', component: TranslationJobComponent },
      { path: 'translation-job/:idjob/screens', component: TranslationJobScreensComponent },
      { path: 'translation-job/:idjob/screens/:idscreen', component: ScreenSingleComponent },

      { path: 'languages', component: LanguagesSummaryComponent },
      { path: 'language-newedit', component: LanguageNewEditComponent },

      { path: 'users', component: UsersSummaryComponent },
      { path: 'users/:iduser', component: UserNewEditComponent },

      { path: 'companies', component: CompaniesSummaryComponent },
      { path: 'companies/:idcompany', component: CompanyNewEditComponent },

      { path: 'settings', component: SettingsComponent },
      { path: 'history', component: HistoryComponent }
    ]),
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    FileDropModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [TextHistoryComponent, DeviceNewEditComponent, TranslationTaskNewEditComponent, LanguagesAssignmentComponent]
})
export class AppModule { }
