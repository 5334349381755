import { User } from './datamodels';

export class UserModel implements User
{
  id: number;
  name: string;
  assigned: boolean;
  eMail?: string;
  inExternal?: number;
  password?: string;
  companyID?: number;
}
