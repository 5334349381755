import { Component, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material';
import { HistoryComponent } from '../history/history.component';
import { Device, ReturnState, DeviceFilter, Task, Job, Global } from '../../datamodels';
import { DeviceNewEditComponent } from '../device-newedit/device-newedit.component';
import { TranslationTaskNewEditComponent } from '../translation-task-newedit/translation-task-newedit.component';
import { LanguagesAssignmentComponent } from '../languages-assignment/languages-assignment.component';
import { UploadEvent, FileSystemFileEntry, FileSystemDirectoryEntry, UploadFile } from 'ngx-file-drop';
import { Router } from '@angular/router';
import { babel } from '../../globals';

@Component({
  selector: 'app-devices-summary',
  templateUrl: './devices-summary.component.html',
  styleUrls: ['./devices-summary.component.css']
})

export class DevicesSummaryComponent
{
  public files: UploadFile[] = [];

  babel: Global = babel;

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  dialogRef: MatDialogRef<HistoryComponent>;
  dialogRefDevice: MatDialogRef<DeviceNewEditComponent>;
  dialogRefTask: MatDialogRef<TranslationTaskNewEditComponent>;
  dialogRefLang: MatDialogRef<LanguagesAssignmentComponent>;

  public filter: DeviceFilter = { filterValue: null };

  public devices: Device[];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router)
  {
    this.loadDevices();
  };

  loadDevices()
  {
    var value = this.filter.filterValue == null ? '' : this.filter.filterValue;
    this.http.get<Device[]>(this.baseUrl + 'api/devices/all/' + value).subscribe(result =>
    {
      this.devices = result;
    }, error => console.error(error));
  };

  openHistory(_deviceID: number, _taskID: number, _jobID: number)
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.deviceID = _deviceID;
    this.dialogRef.componentInstance.filter.translationTaskID = _taskID;
    this.dialogRef.componentInstance.filter.translationJobID = _jobID;
  };

  showDeviceDialog(_deviceID: number)
  {
    this.dialogRefDevice = this.dialog.open(DeviceNewEditComponent, {
      autoFocus: true,
      height: '200px',
      width: '450px'
    });
    this.dialogRefDevice.componentInstance.deviceID = _deviceID;
    this.dialogRefDevice.componentInstance.onSaved.subscribe(() =>
    {
      this.dialogRefDevice.close();
      this.loadDevices();
    });
    this.dialogRefDevice.afterClosed().subscribe(() => { });
  };

  showTaskDialog(_deviceID: number, _taskID: number, _device: Device)
  {
    this.dialogRefTask = this.dialog.open(TranslationTaskNewEditComponent, {
      autoFocus: true,
      height: '200px',
      width: '450px'
    });
    this.dialogRefTask.componentInstance.deviceID = _deviceID;
    this.dialogRefTask.componentInstance.translationTaskID = _taskID;
    this.dialogRefTask.componentInstance.onSaved.subscribe(() =>
    {
      this.dialogRefTask.close();
      this.loadDevices();
    });
    this.dialogRefTask.afterClosed().subscribe(() => { });
  };

  showJobAddDialog(_device: Device, _task: Task)
  {
    if (_task.screensImported === false || _task.defaultTextsImported === false)
    {
      this.snackBarRef = this.snackBar.open('Please ensure that XML- as well as INI-files have been imported first before adding jobs.', 'Close');
      return;
    }

    this.router.navigate(['/devices', _device.id, 'tasks', _task.id, 'newjobs']);
  };

  showLanguageSelectionDialog(_device: Device, _task: Task) 
  {
    this.dialogRefLang = this.dialog.open(LanguagesAssignmentComponent, {
      autoFocus: true,
      height: '80%',
      width: '60%'
    });
    this.dialogRefLang.componentInstance.deviceName = _device.name;
    this.dialogRefLang.componentInstance.deviceID = _device.id;
    this.dialogRefLang.componentInstance.taskName = _task.name;
    this.dialogRefLang.componentInstance.taskID = _task.id;
    this.dialogRefLang.componentInstance.onSaved.subscribe(() =>
    {
      this.dialogRefLang.close();
      this.loadDevices();
    });
    this.dialogRefLang.componentInstance.onUpdated.subscribe(() =>
    {
      this.loadDevices();
    });
    this.dialogRefLang.afterClosed().subscribe(() => { });
  };

  public dropped(_event: UploadEvent, _translationTask: Task)
  {
    this.files = _event.files;
    for (const droppedFile of _event.files)
    {
      // Is it a file?
      if (droppedFile.fileEntry.isFile)
      {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) =>
        {
          const formData = new FormData();
          formData.append('_file', file, droppedFile.relativePath);
          this.http.post(this.baseUrl + 'api/translationtasks/' + _translationTask.id + '/meta', formData)
            .subscribe(data =>
            {
              var d = data as ReturnState;
              if (d.success)
              {
                this.loadDevices();
              }
              else
              {
                if (d.stateID == 2)//XML
                {
                  this.snackBarRef = this.snackBar.open('There has been already an XML-file imported for this translationtask.', 'Close');
                }
                else if (d.stateID == 3) // INI
                {
                  this.snackBarRef = this.snackBar.open('There has been already an INI-file imported for this translationtask.', 'Close');
                }
                else if (d.stateID == 4) // XML missing for INI
                {
                  this.snackBarRef = this.snackBar.open('The XML-file has not yet been imported. Please import before importing INI-file.', 'Close');
                }
                else if (d.stateID == 5) // Language
                {
                  this.snackBarRef = this.snackBar.open('The default-language could not be found. Has the XML-file already been imported?', 'Close');
                }
              }
            })

        });
      }
      else
      {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  };

  public fileOver(event)
  {
    console.log(event);
  };

  public fileLeave(event)
  {
    console.log(event);
  };

  toggleExpandCollapse(_device: Device, _task: Task)
  {
    var type = '';
    var id = 0;
    var state = '';

    if (_device)
    {
      _device.expanded = !_device.expanded;
      type = 'device';
      id = _device.id;
      state = _device.expanded ? 'expanded' : 'collapsed';
    }

    if (_task)
    {
      _task.expanded = !_task.expanded;
      type = 'translationtask';
      id = _task.id;
      state = _task.expanded ? 'expanded' : 'collapsed';
    }

    this.http
      .post<ReturnState>(this.baseUrl + 'api/settings/' + type + '/' + state + '/' + id, null)
      .subscribe(
        result =>
        {
        },
        error => console.error(error));
  };
}
