import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserExtended } from './../../datamodels.d'
import { HistoryComponent } from '../history/history.component';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-users-summary',
  templateUrl: './users-summary.component.html',
  styleUrls: ['./users-summary.component.css']
})

export class UsersSummaryComponent
{
  dialogRef: MatDialogRef<HistoryComponent>;
  public users: UserExtended[];

  displayedColumns: string[] = ['eMail', 'name', 'roles', 'inExternal', 'companyDisplayName', 'edit'];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private dialog: MatDialog)
  {
    http.get<UserExtended[]>(baseUrl + 'api/users/all').subscribe(result =>
    {
      this.users = result;
    }, error => console.error(error));
  };

  openHistory(_userID: number)
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.userID = _userID;
  };
}
