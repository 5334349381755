import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { State } from './../../datamodels.d';
import { babel } from '../../globals';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent
{
  @Output() stateChanged = new EventEmitter<State>();

  isExpanded = false;

  http: HttpClient;
  baseUrl: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string)
  {
    this.http = http;
    this.baseUrl = baseUrl;
  };

  collapse()
  {
    this.isExpanded = false;
  }

  toggle()
  {
    this.isExpanded = !this.isExpanded;
  }

  doLogout()
  {
    this.http
      .get<State>(this.baseUrl + 'api/security/logout')
      .subscribe(result =>
      {
        this.stateChanged.emit(result);
        babel.technicalRoleNames = null;
      }, error => console.error(error));
  }
}
