import { Job } from './datamodels';

export class JobModel implements Job
{
  id: number = 0;
  name: string = '';
  deviceDisplayName: string = null;
  versionDisplayName: string = null;
  versionLatestDisplayName: string = null;
  status: string = null;
  statusDisplayName: string = null;
  screenCount: number = 0;
  languageDisplayName: string = null;
  language: string = null;
  deadline: object = new Date();
  languageID: number = 0;
  versionID: number = 0;
  stateID: number = 0;
  translationTaskID: number = 0;
  companyID: number = 0;
  originalLanguageID: number = 0;
}
