import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, ReturnState, Device, Task } from './../../datamodels.d'
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-translation-task-newedit',
  templateUrl: './translation-task-newedit.component.html',
  styleUrls: ['./translation-task-newedit.component.css']
})

export class TranslationTaskNewEditComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  translationTaskID: number = 0;
  deviceID: number = 0;

  public onSaved = new EventEmitter();

  public translationTask: Task = {
    id: 0,
    name: '',
    translationJobs: null,
    screensImported: false,
    defaultTextsImported: false,
    expanded: true
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
  };

  ngOnInit()
  {
    if (this.translationTaskID === 0)
    {
      return;
    }

    if (this.translationTaskID > 0)
    {
      this.getTranslationTask();
    }
  };

  getTranslationTask()
  {
    this.http.get<Task>(this.baseUrl + 'api/translationtasks/' + this.translationTaskID).subscribe(result =>
    {
      this.translationTask = result;
    }, error => console.error(error));
  };

  saveTranslationTask()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/translationtasks/' + this.deviceID + '/' + this.translationTask.id, this.translationTask)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.translationTask = result.data as Task;
            this.snackBarRef = this.snackBar.open('Translation task saved.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }

          this.onSaved.emit();
        },
        error => console.error(error));
  };
}
