import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, ReturnState } from './../../datamodels.d'
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-company-newedit',
  templateUrl: './company-newedit.component.html',
  styleUrls: ['./company-newedit.component.css']
})

export class CompanyNewEditComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  companyID: number = 0;

  public company: Company = {
    id: 0,
    translationJobID: 0,
    name: '',
    assigned: false
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
  };

  ngOnInit()
  {
    var tmpId = this.route.snapshot.paramMap.get('idcompany');
    if (tmpId === 'new')
    {
      this.companyID = 0;
      return;
    }

    this.companyID = parseInt(tmpId);
    if (this.companyID > 0)
    {
      this.getCompany();
    }
  };

  getCompany()
  {
    this.http.get<Company>(this.baseUrl + 'api/companies/company/' + this.companyID).subscribe(result =>
    {
      this.company = result;
    }, error => console.error(error));
  };

  saveCompany()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/companies/company/' + this.company.id, this.company)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.company = result.data as Company;
            this.snackBarRef = this.snackBar.open('Company saved.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };
}
