import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Screen, JobExtended, Status, User, Company, Language, ReturnState, JobPost } from './../../datamodels.d'
import { MatDialog, MatDialogRef, SimpleSnackBar, MatSnackBarRef, MatSnackBar } from '@angular/material';
import { HistoryComponent } from '../history/history.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { JobExtendedModel } from '../../jobextendedmodel';
import { JobPostModel } from '../../jobpostmodel';

@Component({
  selector: 'app-translation-job',
  templateUrl: './translation-job.component.html',
  styleUrls: ['./translation-job.component.css']
})

export class TranslationJobComponent
{
  dialogRef: MatDialogRef<HistoryComponent>;
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public job: JobExtended = new JobExtendedModel();
  public screens: Screen[];
  public users: User[];
  public companies: Company[];
  public statusJob: Status[];
  public languages: Language[];

  public translationJobID: number = null;
  public selectedCompanyID: number = null;

  screensDisplayedColumns: string[] = ['name'];
  usersDisplayedColumns: string[] = ['assigned', 'name'];
  companiesDisplayedColumns: string[] = ['assigned', 'name'];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
    http.get<Status[]>(baseUrl + 'api/status/translationjob').subscribe(result =>
    {
      this.statusJob = result;
    }, error => console.error(error));
  };

  ngOnInit()
  {
    this.translationJobID = parseInt(this.route.snapshot.paramMap.get('idjob'));
    this.getScreens();
    this.getDetails();
    this.getUsers();
    this.getCompanies();
    this.getLanguages();
  };

  getCompanies()
  {
    this.http.get<Company[]>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/companies').subscribe(result =>
    {
      this.companies = result;
      for (var i = 0; i < this.companies.length; i++)
      {
        if (this.companies[i].translationJobID !== null)
        {
          this.selectedCompanyID = this.companies[i].id;
          break;
        }
      }

    }, error => console.error(error));
  };

  getLanguages()
  {
    this.http.get<Language[]>(this.baseUrl + 'api/languages/translationjob/' + this.translationJobID).subscribe(result =>
    {
      this.languages = result;
    }, error => console.error(error));
  };

  getUsers()
  {
    this.http.get<User[]>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/users').subscribe(result =>
    {
      this.users = result;
    }, error => console.error(error));
  };

  getDetails()
  {
    this.http.get<JobExtended>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/details').subscribe(result =>
    {
      this.job = result;
      if (this.job)
      {
        this.selectedCompanyID = this.job.companyID;
      }
    }, error => console.error(error));
  };

  getScreens()
  {
    this.http.get<Screen[]>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/screens').subscribe(result =>
    {
      this.screens = result;
    }, error => console.error(error));
  };

  save()
  {
    // Get assigned users and company
    var j: JobPost = new JobPostModel();
    j.id = this.job.id;
    j.deadline = this.job.deadline;
    j.languageID = this.job.languageID;
    j.stateID = this.job.stateID;
    j.translationTaskID = this.job.translationTaskID;
    j.versionID = this.job.versionID;
    j.companyID = this.selectedCompanyID;
    j.userIDs = [];

    for (var u = 0; u < this.users.length; u++)
    {
      if (this.users[u].assigned === true)
      {
        j.userIDs.push(this.users[u].id);
      }
    }

    this.http
      .post<ReturnState>(this.baseUrl + 'api/translationjobs/' + this.translationJobID, j)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Translation job saved.', 'Close');
          }
          else
          {
            if (result.stateID == 99)
            {
              this.snackBarRef = this.snackBar.open('This state-transition is not allowed! Please choose another state.', 'Close');
            }
            else if (result.stateID == 98)
            {
              this.snackBarRef = this.snackBar.open('Updating user- or company-assignment only allowd in state DRAFT_NEW.', 'Close');
              this.getDetails();
            }
            else
            {
              this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
              this.getDetails();
            }
          }
        },
        error => console.error(error));
  };

  exportToINI()
  {
    const url = this.baseUrl + 'api/texts/translationjob/' + this.translationJobID + '/ini';
    window.open(url);
  };

  openHistory()
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.translationJobID = this.translationJobID;
  };
}
