import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MatSnackBar, SimpleSnackBar, MatSnackBarRef, fadeInContent } from '@angular/material';
import { Screen, Language, JobExtended, CommentExtended, Status, TextExtended, ReturnState, TextsPost } from './../../datamodels.d'

import { TextHistoryComponent } from '../text-history/text-history.component';
import { HistoryComponent } from '../history/history.component';
import { ActivatedRoute, Router } from '@angular/router';
import { JobExtendedModel } from '../../jobextendedmodel';
import { TextExtendedModel } from '../../textextendedmodel';

@Component({
  selector: 'app-screen-single',
  templateUrl: './screen-single.component.html',
  styleUrls: ['./screen-single.component.css']
})

export class ScreenSingleComponent 
{
  dialogTextRef: MatDialogRef<TextHistoryComponent>;
  dialogRef: MatDialogRef<HistoryComponent>;

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public translationJobID: number = null;
  public screenID: number = null;

  public mode: number = 0;

  public textID: number = null;

  public screenIndex: number = 0;

  public selectedTextOfScreen: TextExtended = new TextExtendedModel();
  public textsOfScreen: TextExtended[] = [];
  public languages: Language[] = [];
  public screen: Screen;
  public screens: Screen[] = [];
  public job: JobExtended = new JobExtendedModel();
  public comments: CommentExtended[] = [];
  public statusText: Status[] = [];

  public commentsVisible: boolean = true;
  public textInfoVisible: boolean = true;

  public imageUrl: string = '';

  public newComment: CommentExtended = {
    authorDisplayName: 'It\'s me',
    dateTime: new Date(),
    comment: ''
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
  };

  ngOnInit()
  {
    this.translationJobID = parseInt(this.route.snapshot.paramMap.get('idjob'));
    this.screenID = parseInt(this.route.snapshot.paramMap.get('idscreen'));

    this.getTextsOfScreen();
    this.getLanguages();
    this.getDetailsOfScreen();
    this.getDetailsOfJob();
    this.getCommentsOfText();
    this.getStatesForTexts();
    this.getScreensOfJob();

    this.setImageUrl();
  };

  setImageUrl()
  {
    var textID = null;
    if (this.selectedTextOfScreen)
    {
      textID = this.selectedTextOfScreen.id;
    }
    this.imageUrl = '/api/translationjobs/screen/' + this.screenID + '/job/' + this.translationJobID + '/text/' + textID;
  };

  toggleComments()
  {
    this.commentsVisible = !this.commentsVisible;
  };

  toggleTextInfo()
  {
    this.textInfoVisible = !this.textInfoVisible;
  };

  determineIndexOfScreen()
  {
    for (var i = 0; i < this.screens.length; i++)
    {
      if (this.screens[i].id === this.screenID)
      {
        this.screenIndex = i;
        break;
      }
    }
  };

  getScreenRelatedData()
  {
    this.getDetailsOfScreen();
    this.getTextsOfScreen();
    this.setImageUrl();
  };

  getLanguages()
  {
    this.http.get<Language[]>(this.baseUrl + 'api/languages/translationjob/' + this.translationJobID).subscribe(result =>
    {
      this.languages = result;
    }, error => console.error(error));
  };

  getStatesForTexts()
  {
    this.http.get<Status[]>(this.baseUrl + 'api/status/text/').subscribe(result =>
    {
      this.statusText = result;
    }, error => console.error(error));
  };

  getDetailsOfScreen()
  {
    this.http.get<Screen>(this.baseUrl + 'api/screens/details/' + this.screenID).subscribe(result =>
    {
      this.screen = result;
    }, error => console.error(error));
  };

  getScreensOfJob()
  {
    this.http.get<Screen[]>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/screens').subscribe(result =>
    {
      this.screens = result;
      this.determineIndexOfScreen();
    }, error => console.error(error));
  };

  getTextsOfScreen()
  {
    this.http.get<TextExtended[]>(this.baseUrl + 'api/texts/translationjob/' + this.translationJobID + '/ofscreen/' + this.screenID).subscribe(result =>
    {
      this.textsOfScreen = result;

      if (result && result.length > 0)
      {
        this.selectedTextOfScreen = result[0];
        this.getCommentsOfText();
      }
    }, error => console.error(error));
  };

  getDetailsOfJob()
  {
    this.http.get<JobExtended>(this.baseUrl + 'api/translationjobs/' + this.translationJobID + '/details').subscribe(result =>
    {
      this.job = result;
    }, error => console.error(error));
  };

  getCommentsOfText()
  {
    if (this.selectedTextOfScreen === null || this.selectedTextOfScreen.id === 0)
    {
      return;
    }

    this.http.get<CommentExtended[]>(this.baseUrl + 'api/comments/translationjob/' + this.translationJobID + '/text/' + this.selectedTextOfScreen.id).subscribe(result =>
    {
      this.comments = result;
    }, error => console.error(error));
  };

  openHistory()
  {
    this.dialogRef = this.dialog.open(HistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '90%'
    });
    this.dialogRef.componentInstance.filter.screenID = this.screenID;
  };

  openTextHistory()
  {
    this.dialogTextRef = this.dialog.open(TextHistoryComponent, {
      autoFocus: true,
      height: '80%',
      width: '600px'
    });
  };

  addComment()
  {
    this.newComment.dateTime = new Date();
    this.storeComment(this.newComment);
  };

  changeMode()
  {
    if (this.mode === 0)
    {
      this.mode = 1;
    }
    else if (this.mode === 1)
    {
      this.mode = 0;
    }
  };

  isTextMode()
  {
    return this.mode === 1;
  };

  isTotalMode()
  {
    return this.mode === 0;
  };

  nextScreen()
  {
    if (this.screenIndex < this.screens.length - 1)
    {
      this.screenIndex++;
      this.screenID = this.screens[this.screenIndex].id;
      this.router.navigate(['/translation-job', this.translationJobID, 'screens', this.screenID]);

      this.getScreenRelatedData();
    }
  };

  previousScreen()
  {
    if (this.screenIndex > 0)
    {
      this.screenIndex--;
      this.screenID = this.screens[this.screenIndex].id;
      this.router.navigate(['/translation-job', this.translationJobID, 'screens', this.screenID]);

      this.getScreenRelatedData();
    }
  };

  storeComment(_comment)
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/comments/translationjob/' + this.translationJobID + '/text/' + this.selectedTextOfScreen.id, _comment)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Comment saved.', 'Close');

            this.comments.push(_comment);
            this.newComment = {
              authorDisplayName: 'It\'s me',
              dateTime: new Date(),
              comment: ''
            };
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };

  saveText()
  {
    var textsPostModel: TextsPost = {
      textID: this.selectedTextOfScreen.id,
      stateID: this.selectedTextOfScreen.stateID,
      toTranslate: this.selectedTextOfScreen.toTranslate,
      text: this.selectedTextOfScreen.text
    };

    this.http
      .post<ReturnState>(this.baseUrl + 'api/texts/translationjob/' + this.translationJobID + '/ofscreen/' + this.screenID, textsPostModel)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Text saved.', 'Close');

            var d = result.data[0] as TextExtended;
            this.selectedTextOfScreen.stateID = d.stateID;
            this.selectedTextOfScreen.stateDisplayName = d.stateDisplayName;
            this.selectedTextOfScreen.toTranslate = d.toTranslate;
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };

  confirmText()
  {
    this.http
      .post<ReturnState>(this.baseUrl + 'api/texts/translationjob/' + this.translationJobID + '/ofscreen/' + this.screenID + '/confirm/' + this.selectedTextOfScreen.id, null)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.snackBarRef = this.snackBar.open('Text has been confirmed.', 'Close');

            var d = result.data[0] as TextExtended;
            this.selectedTextOfScreen.stateID = d.stateID;
            this.selectedTextOfScreen.stateDisplayName = d.stateDisplayName;
            this.selectedTextOfScreen.toTranslate = d.toTranslate;
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };

  onTextSelected(_textID: number)
  {
    for (var i = 0; i < this.textsOfScreen.length; i++)
    {
      if (this.textsOfScreen[i].id === _textID)
      {
        this.selectedTextOfScreen = this.textsOfScreen[i];
        this.getCommentsOfText();
        this.setImageUrl();
        break;
      }
    }
  };
}
