import { Task, Job } from './datamodels';

export class TaskModel implements Task
{
  id: number;
  name: string;
  translationJobs: Job[];
  screensImported: boolean;
  defaultTextsImported: boolean;
  expanded: boolean;
}
