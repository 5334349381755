import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Screen, Language, JobExtended, CommentExtended, Status, TextHistoryFilter, User, TextExtended } from './../../datamodels.d'
import { TextHistoryFilterModel } from '../../texthistoryfiltermodel';

@Component({
  selector: 'app-text-history',
  templateUrl: './text-history.component.html',
  styleUrls: ['./text-history.component.css']
})

export class TextHistoryComponent 
{
  public filter: TextHistoryFilter = new TextHistoryFilterModel();
  //{
  //  count: 100,
  //  translationJobID: null,
  //  languageID: null,
  //  userID: null,
  //  deviceID: null,
  //  translationTaskID: null
  //};

  public languages: Language[];
  public translationjobs: JobExtended[];
  public users: User[];
  public historyItems: TextExtended[];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string)
  {
    http.get<Language[]>(baseUrl + 'api/languages/all').subscribe(result =>
    {
      this.languages = result;
    }, error => console.error(error));

    http.get<JobExtended[]>(baseUrl + 'api/texts/translationjobs').subscribe(result =>
    {
      this.translationjobs = result;
    }, error => console.error(error));

    http.get<User[]>(baseUrl + 'api/users/all').subscribe(result =>
    {
      this.users = result;
    }, error => console.error(error));

    http.get<TextExtended[]>(baseUrl + 'api/texts/history').subscribe(result =>
    {
      this.historyItems = result;
    }, error => console.error(error));

  };
}
