import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language, JobExtended, TextHistoryFilter, User, HistoryExtended, Device, Task, Screen, ScreenLight, Company } from './../../datamodels.d'
import { JobExtendedModel } from '../../jobextendedmodel';
import { LanguageModel } from '../../languagemodel';
import { UserModel } from '../../usermodel';
import { TaskModel } from '../../taskmodel';
import { DeviceModel } from '../../devicemodel';
import { ScreenLightModel } from '../../screenlightmodel';
import { CompanyModel } from '../../companymodel';
import { TextHistoryFilterModel } from '../../texthistoryfiltermodel';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})

export class HistoryComponent 
{
  public filter: TextHistoryFilter = new TextHistoryFilterModel();

  public devices: Device[];
  public translationTasks: Task[];
  public languages: Language[];
  public translationjobs: JobExtended[];
  public users: User[];
  public historyItems: HistoryExtended[];
  public screens: ScreenLight[];
  public companies: Company[];

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
  {
    this.getDevices();
    this.getTranslationTasks();
    this.getUsers();
    this.getLanguages();
    this.getTranslationJobs();
    this.getScreens();
    this.getCompanies();
  };

  ngOnInit()
  {
    this.getHistory();
  };

  getCompanies()
  {
    this.http.get<Company[]>(this.baseUrl + 'api/companies/all').subscribe(result =>
    {
      var empty = new CompanyModel();
      empty.id = null;
      empty.name = '<no selection>';

      var tmp = [];
      tmp.push(empty);
      tmp.push.apply(tmp, result);
      this.companies = tmp;
    }, error => console.error(error));
  };

  getScreens()
  {
    this.http.get<ScreenLight[]>(this.baseUrl + 'api/screens/all/light/fullname').subscribe(result =>
    {
      var empty = new ScreenLightModel();
      empty.id = null;
      empty.fullName = '<no selection>';

      var tmp = [];
      tmp.push(empty);
      tmp.push.apply(tmp, result);
      this.screens = tmp;
    }, error => console.error(error));
  };

  getDevices()
  {
    this.http.get<Device[]>(this.baseUrl + 'api/devices/all/notree').subscribe(result =>
    {
      var emptyDevice = new DeviceModel();
      emptyDevice.id = null;
      emptyDevice.name = '<no selection>';

      var tmp = [];
      tmp.push(emptyDevice);
      tmp.push.apply(tmp, result);
      this.devices = tmp;
    }, error => console.error(error));
  };

  getTranslationTasks()
  {
    this.http.get<Task[]>(this.baseUrl + 'api/translationtasks/all').subscribe(result =>
    {
      var emptyTask = new TaskModel();
      emptyTask.id = null;
      emptyTask.name = '<no selection>';

      var tmp = [];
      tmp.push(emptyTask);
      tmp.push.apply(tmp, result);
      this.translationTasks = tmp;
    }, error => console.error(error));
  };

  getUsers()
  {
    this.http.get<User[]>(this.baseUrl + 'api/users/all').subscribe(result =>
    {
      var emptyUser = new UserModel();
      emptyUser.id = null;
      emptyUser.name = '<no selection>';

      var tmp = [];
      tmp.push(emptyUser);
      tmp.push.apply(tmp, result);
      this.users = tmp;
    }, error => console.error(error));
  };

  getLanguages()
  {
    this.http.get<Language[]>(this.baseUrl + 'api/languages/all').subscribe(result =>
    {
      var emptyLang = new LanguageModel();
      emptyLang.id = null;
      emptyLang.name = '<no selection>';

      var tmp = [];
      tmp.push(emptyLang);
      tmp.push.apply(tmp, result);
      this.languages = tmp;

    }, error => console.error(error));
  };

  getTranslationJobs()
  {
    this.http.get<JobExtended[]>(this.baseUrl + 'api/translationjobs/all/combinedname').subscribe(result =>
    {
      var emptyJob = new JobExtendedModel();
      emptyJob.id = null;
      emptyJob.name = '<no selection>';

      var tmp = [];
      tmp.push(emptyJob);
      tmp.push.apply(tmp, result);
      this.translationjobs = tmp;

    }, error => console.error(error));
  };

  getHistory()
  {
    var url = this.baseUrl + 'api/history/filtered/'
      + this.filter.count
      + '/'
      + this.filter.userID
      + '/'
      + this.filter.deviceID
      + '/'
      + this.filter.translationTaskID
      + '/'
      + this.filter.translationJobID
      + '/'
      + this.filter.screenID
      + '/'
      + this.filter.languageID
      + '/'
      + this.filter.companyID;

    this.http.get<HistoryExtended[]>(url).subscribe(result =>
    {
      this.historyItems = result;
    }, error => console.error(error));
  };

  refresh()
  {
    this.getHistory();
  };
}
