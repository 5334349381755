import { Component, Inject, EventEmitter, Output, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login, State } from './../../datamodels.d';
import { babel } from '../../globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent
{
  @Output() stateChanged = new EventEmitter<State>();
  @Input() isLocal: boolean = false;

  public login: Login;
  public stateLogin: State;

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private snackBar: MatSnackBar)
  {
    this.baseUrl = baseUrl;
    this.http = http;

    this.login = {
      username: '',
      password: ''
    };
  };

  doLogin()
  {
    if (this.snackBarRef)
    {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }

    this.http
      .post<State>(this.baseUrl + 'api/security/login', this.login)
      .subscribe(
        result =>
        {
          if (result.value === false)
          {
            this.snackBarRef = this.snackBar.open('Login failed. Please check username and password.', 'Close');
            babel.technicalRoleNames = null;
          }
          else
          {
            babel.technicalRoleNames = result.technicalRoleNames;
          }

          this.stateChanged.emit(result);
        },
        error => console.error(error));

    return false;
  };

  doInitUser()
  {
    this.http
      .post<State>(this.baseUrl + 'api/security/inituser', null)
      .subscribe(
        result =>
        {
          if (result.value === false)
          {
            this.snackBarRef = this.snackBar.open('Init user done.', 'Close');
          }

          this.stateChanged.emit(result);
        },
        error => console.error(error));
  };
}
