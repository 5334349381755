import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language } from './../../datamodels.d'

@Component({
  selector: 'app-language-newedit',
  templateUrl: './language-newedit.component.html',
  styleUrls: ['./language-newedit.component.css']
})

export class LanguageNewEditComponent
{
  public language: Language = {
    id: 0,
    name: '',
    culture: ''
  };

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string)
  {
    //http.get<Language[]>(baseUrl + 'api/languages/all').subscribe(result =>
    //{
    //  this.languages = result;
    //}, error => console.error(error));
  };
}
