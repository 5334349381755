import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserExtended, Company, Role, State, ReturnState, RoleSelection } from './../../datamodels.d'
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';

@Component({
  selector: 'app-user-newedit',
  templateUrl: './user-newedit.component.html',
  styleUrls: ['./user-newedit.component.css']
})

export class UserNewEditComponent
{
  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  public userID: number = 0;

  public user: UserExtended = {
    id: 0,
    name: '',
    assigned: false,
    companyDisplayName: '',
    eMail: '',
    inExternal: 0,
    roles: [],
    roleIDs: [],
    password: '',
    companyID: 0
  };
  public companies: Company[];
  public roles: RoleSelection[];

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar)
  {
    this.getRoles();
    this.getCompanies();
  };

  ngOnInit()
  {
    var tmpId = this.route.snapshot.paramMap.get('iduser');
    if (tmpId === 'new')
    {
      this.userID = 0;
      return;
    }

    this.userID = parseInt(tmpId);
    if (this.userID > 0)
    {
      this.getUser();
    }
  };

  getUser()
  {
    this.http.get<UserExtended>(this.baseUrl + 'api/users/user/' + this.userID).subscribe(result =>
    {
      this.user = result;
      for (var i = 0; i < this.roles.length; i++)
      {
        this.roles[i].selected = this.user.roleIDs.indexOf(this.roles[i].id) >= 0;
      }
    }, error => console.error(error));
  };

  getRoles()
  {
    this.http.get<RoleSelection[]>(this.baseUrl + 'api/roles/all').subscribe(result =>
    {
      this.roles = result;
    }, error => console.error(error));
  };

  getCompanies()
  {
    this.http.get<Company[]>(this.baseUrl + 'api/companies/all').subscribe(result =>
    {
      this.companies = result;
    }, error => console.error(error));
  };

  saveUser()
  {
    var roleIDs = [];
    for (var i = 0; i < this.roles.length; i++)
    {
      if (this.roles[i].selected === true)
      {
        roleIDs.push(this.roles[i].id);
      }
    }
    this.user.roleIDs = roleIDs;

    this.http
      .post<ReturnState>(this.baseUrl + 'api/users/user/' + this.user.id, this.user)
      .subscribe(
        result =>
        {
          if (result.success)
          {
            this.user = result.data as UserExtended;
            this.snackBarRef = this.snackBar.open('User saved.', 'Close');
          }
          else
          {
            this.snackBarRef = this.snackBar.open('Something went wrong! Please try again!', 'Close');
          }
        },
        error => console.error(error));
  };
}
