import { JobExtended } from './datamodels';
import { JobModel } from './jobmodel';

export class JobExtendedModel extends JobModel implements JobExtended
{
  //id: number;
  //name: string;
  //deviceDisplayName: string;
  //versionDisplayName: string;
  //versionLatestDisplayName: string;
  //status: string;
  //statusDisplayName: string;
  //screenCount: number;
  //languageDisplayName: string;
  //language: string;
  //deadline: object;
  //languageID: number;
  //versionID: number;
  //stateID: number;
  //translationTaskID: number;
  //companyID: number;
  //originalLanguageID: number;
}
